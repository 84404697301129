<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				data:{},
				popularBlog:{},
				popularTags:{},
			}
		},
		computed:{
			myParams(){
				let query = Gen.clone(this.$route.query)
				if(this.$route.query.tag) query.tag = this.$route.query.tag
				return query
			}
		},
		async mounted() {
			await this.ready();
			Vue.component("Header",()=>import("@frontend/part/Header.vue"))
            this.$set(this.$root, 'page', this);

            this.getApi()
		},
		methods:{
			getApi(callBack=null) {
				Gen.apirest('/list-tags/'+this.$route.params.slug, this.myParams, (err, resp)=>{
					if(err) console.log(err)
					if(callBack) return callBack(resp)
					this.data = resp.data
				})
			},
			onPaging(page = 1){
				if($(".page-item.active").text() == page) return 
				var query = Object.assign(Gen.clone(this.$route.query),{
					page:page
				})
				this.$router.push({
					name:this.$route.name,
					query:query
				})
			}
		},
	};
</script>
<template>
	<div>
		<section id="content" style="overflow: visible">
		    <div class="content-wrap p_0"><div class="clear"></div>
		    <div class="section  notopmargin nobottommargin">
		        <div class="container clearfix">
		            <div class="row justify-content-center">
		                <div class="col-md-10 center">
		                    <div class="heading_section">
		                        <h2>Berita</h2>
		                    </div>
		                </div>
		            </div>
		            <div class="row justify-content-center">
		                <div class="col-md-9">
		                    <div class="row">
		                        <div class="col-md-12" v-for="(v,k) in data.data" :key="k">
		                            <div class="news_wrapper">
		                                <div class="row">
		                                    <div class="col-md-4">
		                                        <div class="news_thumb">
		                                        	<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}"><img :src="uploader(v.image)" :alt="v.title" :title="v.title"></router-link>
		                                        </div>
		                                    </div>
		                                    <div class="col-md-6">
		                                        <div class="news_content_post">
		                                            <h3>{{ v.title }}</h3>
		                                            <p v-html="v.excerpt"></p>
		                                            <ul>
		                                                <li>{{ (v.publish_date||"").dates("format") }}</li>
		                                                <li>{{ v.author }}</li>
		                                            </ul>
		                                        </div>
		                                    </div>
		                                    <div class="col-md-2">
		                                    	<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}" class="btn_more">
		                                        	<i class="icon-angle-right"></i>
		                                    	</router-link>
		                                    </div>
		                                </div>
		                            </div>
		                        </div>

		                	</div>
		                	<Pagination class="justify-content-center" :data="data" :limit="4" @pagination-change-page="onPaging"></Pagination>
		            	</div>
			            <div class="col-md-3">
			                <aside class="post_recent mtop_120">
			                    <h3>POPULAR POSTS </h3>
			                    <ul>
			                        <li v-for="(v,k) in popularBlog" :key="k">
			                        	<router-link :to="{name:'DetailBlog', params:{slug:v.slug}}">
			                        		{{ v.title }}
			                        	</router-link>
			                    	</li>
			                	</ul>
			            </aside>
			            <aside class="post_recent">
			                <h3>Tags </h3>
			                <div class="tagcloud">
			                    <a v-for="(v,k) in popularTags" :key="k" href="#" class=""> {{ v }} </a>
			                </div>
			            </aside>
			        </div>
		    	</div>
			</div>
		</div>
		<div class="line line-sm my-0 clearfix"></div>
		<div class="clear"></div>
		</div>
		</section>
	</div>
</template>